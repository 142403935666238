<template>
  <div class="share-page">
    <div class="share-area">
      <div
        style="
          width: 100%;
          aspect-ratio: 16/9;
          background-color: white;
          border-radius: 5px;
        "
      >
        <slot name="image-content" />
      </div>
      <div class="share-info">
        <v-row class="ma-0" align="center">
          <slot name="footer" />
          <v-spacer />
          <v-img
            v-on:click="$helper.openTab('https://alinocam.com')"
            style="cursor: pointer"
            class="mt-1"
            max-width="210"
            src="@/assets/logo/alinocam/text_medium.png"
          />
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SharePageTemplate',
};
</script>

<style scoped>
.share-area {
  width: calc(100vw - 90px);
  max-width: 1500px;

  position: absolute;
  left: 50%;
  margin-top: 5px;
  transform: translateX(-50%);
  border-radius: 5px !important;
  overflow: hidden !important;
}
.share-page {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.share-info {
  height: 50px;
  width: 100%;
  background-color: transparent;
}
</style>
